<template>
    <div>
        <h4 class="title">{{ title }}</h4>
        <p class="sub-title">{{ subTitle }}</p>
    </div>
</template>
  
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
}

.sub-title {
    margin-top: 15px;
    font-size: 14px;
    color: #575757;
}
</style>
  