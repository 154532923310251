<template>
    <b-form-group :label="label" :label-for="selectId" class="form-col">
        <b-form-select :class="{ 'selected': localModel }" class="custom-select" v-model="localModel" :options="options"
            :name="name" :id="selectId" :placeholder="placeholder" v-validate="'required'">
            <template #first>
                <b-form-select-option :value="null" disabled>
                    {{ placeholder }}
                </b-form-select-option>
            </template>
        </b-form-select>
        <b-form-invalid-feedback :state="!inputErrors.has(name)">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>
  
<script>
export default {
    props: {
        model: {
            type: String | null,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        selectId: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        errorMessage: {
            type: String,
            required: true
        },
        inputErrors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localModel: this.model
        }
    },
    watch: {
        model(newValue) {
            this.localModel = newValue
        },
        localModel(newValue) {
            this.$parent[this.name] = newValue
            this.$emit('update:model', newValue)
        }
    }
}
</script>
<style lang="scss" scoped>
select {
    margin: 0px !important;
    padding: 16px 24px 16px 24px !important;
    border-radius: 4px !important;
    border: 1px solid #DEDEDE !important;
    color: #81858e !important;
}

.selected {
    color: #000 !important;
}


.custom-select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('~@/assets/img/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: 97%;
    background-size: 12px;
    padding-right: 30px;
}


label {
    font-weight: 600 !important;
    margin-bottom: 4px !important;
    font-size: 16px !important;
    line-height: 24px !important;
}
</style>