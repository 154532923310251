<template>
    <span class="logo mb-5">
        <img src="@/assets/img/logo.svg" />
    </span>
</template>
  
<script>
export default {
    props: ['src']
}
</script>
<style lang="scss" scoped>
.logo {
    img {
        width: 138.83px;
        margin-bottom: 40px;
    }
}
 
</style>