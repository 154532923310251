<!-- FormInput.vue -->
<template>
    <div>
        <b-form-group :label="label" :label-for="id" class="form-col">
            <b-form-input :id="id" :placeholder="placeholder" v-model="localModel" v-mask="mask" :type="type" :name="name"
                :v-validate="validation" v-on="$listeners" v-if="mask"></b-form-input>
            <b-form-input :id="id" :placeholder="placeholder" v-model="localModel" :type="type" :name="name"
                :v-validate="validation" v-on="$listeners" v-else></b-form-input>
            <span class="mostrar-senha"  @click="mostrarSenha" v-if="type === 'password'"></span>
            <b-form-invalid-feedback :state="submit ? !inputErrors.has(name) : null">
                {{ errorMessage }}
            </b-form-invalid-feedback>
        </b-form-group>
    </div>
</template>
  
<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        model: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        validation: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            required: true
        },
        submit: {
            type: Boolean,
            required: true
        },
        inputErrors: {
            type: Object,
            required: true
        },
        mask: {
            type: [String, Array],
            required: false,
        }
    },
    data() {
        return {
            localModel: this.model,
            showPassword: false
        }
    },
    watch: {
        model(newValue) {
            this.localModel = newValue
        },
        localModel(newValue) {
            this.$parent[this.name] = newValue
            this.$emit('update:model', newValue)
        }
    },
    methods: {
        mostrarSenha() {
            const input = document.querySelector("#password");
            this.showPassword = !this.showPassword;
            if (this.showPassword) {
                input.setAttribute("type", "text");
            } else {
                input.setAttribute("type", "password");
            }
        },
    }

}
</script>
<style lang="scss" scoped>
.mostrar-senha {
    font-size: 13px;
    background: rgb(255 255 255 / 3%) url(/img/eye-off.232e5a7d.svg);
    position: absolute;
    top: 45%;
    right: 20px;
    cursor: pointer;
    content: "";
    padding: 8px;
}

label {
    font-weight: 600 !important;
    margin-bottom: 4px !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

input[type="text"],
input[type="password"],
input[type="email"] {
    height: 53px !important;
    padding: 16px 24px 16px 24px !important;
    border-radius: 4px !important;
    border: 1px solid #DEDEDE !important;

    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

}

input[type=password]::placeholder {
    font-size: 14px !important;
}
</style>