<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side"> </b-col>

      <b-col cols="12" md="6" class="right-side">
        <div class="inner-wrapper" data-anima="top">
          <Logo />
          <BackLink to="/" :text="$t('views.seller.register.text_1432')" />
          <FormTitle :title='$t("views.seller.register.text_1454")' :subTitle='$t("views.seller.register.text_1455")'/>
          <b-form @submit.prevent="onSubmit" novalidate>
            <b-row class="">
              <b-col cols="12" md="6" class="mt-4 pr-md-3">
                <FormInput :label="$t('views.seller.register.text_1433')" id="name"
                  :placeholder="$t('views.seller.register.text_1312')" :model="name" type="text" name="name"
                  validation="'required'" :errorMessage="$t('views.seller.register.text_1313')" :submit="submit"
                  :inputErrors="errors" />
              </b-col>
              <b-col cols="12" md="6" class="mt-4">
                <FormInput :label="$t('views.seller.register.text_1434')" id="phone"
                  :placeholder="$t('views.seller.register.text_1314')" :model="phone" type="text" name="phone"
                  :mask="['(##) #####-####', '(##) ####-####']"  validation="'required'"
                  :errorMessage="$t('views.seller.register.text_1315')" :submit="submit" :inputErrors="errors" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="mt-4 pr-md-3">
                <FormInput :label="$t('views.seller.register.text_1319')" id="cpf"
                  :placeholder="$t('views.seller.register.text_1456')" :model="cpf" type="text" name="cpf"
                  mask="###.###.###-##" validation="'required|cpf_cnpj'"
                  :errorMessage="$t('views.seller.register.text_1319')" :submit="submit" :inputErrors="errors" />
              </b-col>

              <b-col cols="12" md="6" class="mt-4 ">
                <FormInput :label="$t('views.seller.register.text_1435')" id="cnpj"
                  :placeholder="$t('views.seller.register.text_1457')" :model="cnpj" type="text" name="cnpj"
                  mask="##.###.###/####-##" validation="'required|cpf_cnpj'"
                  :errorMessage="$t('views.seller.register.text_1436')" :submit="submit" :inputErrors="errors" />
              </b-col>
            </b-row>
            
            <b-row>
              <b-col class="mt-4">
                <FormSelect :label="$t('views.seller.register.how_do_you_find_voomp')" :selectId="'howDidYouFindVoomp'"
                  :name="'howDidYouFindVoomp'" :placeholder="$t('views.seller.register.text_1317')"
                  :options="find_options" :errorMessage="$t('views.seller.register.text_1317')"
                  :inputErrors="errors" :model="howDidYouFindVoomp" />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mt-4">
                <FormSelect :label="$t('views.seller.register.text_1316')" :selectId="'seller_type'"
                  :name="'seller_type'" :placeholder="$t('views.seller.register.text_1317')"
                  :options="seller_type_options" :errorMessage="$t('views.seller.register.text_1317')"
                  :inputErrors="errors" :model="seller_type" />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" class="mt-4">
                <FormSelect :label="$t('views.seller.register.text_1318')" :selectId="'affiliated_options'"
                  :name="'affiliated'" :placeholder="$t('views.seller.register.text_1317')"
                  :options="affiliated_options" :errorMessage="$t('views.seller.register.text_1317')"
                  :inputErrors="errors" :model="affiliated" />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" class="mt-4 mb-4">
                <FormInput :label="$t('views.seller.register.text_1436')" id="email"
                  :placeholder="$t('views.seller.register.text_1437')" :model="email" type="email" name="email"
                  validation="'required|email'" :errorMessage="$t('views.seller.register.text_1322')" :submit="submit"
                  :inputErrors="errors" />
              </b-col>
              <b-col cols="12" md="6" class="mt-4 pl-md-3">
                <FormInput :label="$t('views.seller.register.text_1323')" id="password"
                  :placeholder="$t('views.seller.register.text_1438')" :model="password" type="password"
                  name="password" validation="'required|min:8'"
                  :errorMessage="$t('views.seller.register.text_1322')" :submit="submit" :inputErrors="errors" />        
              </b-col>
            </b-row>

            <b-form-group label="" label-for="accepted_terms" class="termos-uso">
              <b-form-checkbox id="accepted_terms" v-model="accepted_terms" name="accepted_terms" v-validate="'required'">
                <p class="text-termos">
                  {{ $t("views.seller.register.text_1440") }}
                  <a href="https://www.voompcreators.com.br/termos-e-documentacoes/" target="_blank">{{
                    $t("views.seller.register.text_1325") }}
                  </a>
                  {{ $t("views.seller.register.text_1439") }}
                </p>
              </b-form-checkbox>
            </b-form-group>

            <b-row class="mt-4">
              <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary"
                class="d-inline-block col-12">
                <Captcha ref="Captcha" v-if="accepted_terms" @changeVerify="changeVerify">
                  <BaseButton variant="secondary" ref="button" type="submit" class="btn-submit mb-5" @click="onSubmit">
                    {{ $t("views.seller.register.text_1326") }}
                  </BaseButton>
                </Captcha>
                <BaseButton v-else variant="secondary" ref="button" disabled type="submit" class="btn-submit mb-5"
                  @click="onSubmit">
                  {{ $t("views.seller.register.text_1326") }}
                </BaseButton>
              </b-overlay>
            </b-row>
            <b-row class="mt-4 linha-possui-conta">
              <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="btn-acoes">
                {{ $t("views.seller.login.text_1573") }} <router-link :to="{ name: 'Login' }" class="recupera-senha-text">
                  &nbsp;{{ $t("views.seller.login.text_1574") }} <img
                    src="~@/assets/img/icons/long-arrow-orange.svg" /></router-link>
              </b-overlay>
            </b-row>
            <div class="termos-privacidade">
              <a class="links-help mr-1" href="https://www.voompcreators.com.br/termos-e-documentacoes/"
                target="_blank">{{ $t("views.seller.login.text_1568") }}</a>
              <span class="links-help mr-1">|</span>
              <a class="links-help" href="https://www.canaldatransparencia.com.br/cogna/" target="_blank">{{
                $t("views.seller.login.text_1566") }}</a>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SellerRegisterService from "@/services/resources/SellerRegisterService";
const service = SellerRegisterService.build();
 
import Captcha from "@/components/security/Captcha.vue";
import Logo from "@/components/shared/Logo.vue";
import FormInput from "@/components/shared/forms/FormInput.vue";
import FormTitle from "@/components/shared/forms/FormTitle.vue";
import BackLink from "@/components/shared/BackLink.vue";
import FormSelect from "@/components/shared/forms/FormSelect.vue";

export default {
  name: "Login",
  components: {
    Captcha,
    Logo,
    FormInput,
    FormTitle,
    BackLink,
    FormSelect
},
  data() {
    return {
      submit: false,
      name: "",
      email: "",
      password: "",
      cpf: "",
      cnpj: "",
      phone: "",
      accepted_terms: false,
      success: true,
      loading: false,
      hash: null,
      id_product: null,
      id_indicacao: null,
      seller_type: null,
      affiliated: null,
      howDidYouFindVoomp: null,
      find_options: [
        { value: "friend", text: this.$t("views.seller.register.through_friend") },
        { value: "ad", text: this.$t("views.seller.register.through_ad") },
        { value: "blog", text: this.$t("views.seller.register.through_blog") },
        { value: "event", text: this.$t("views.seller.register.through_event") },
        { value: "podcast", text: this.$t("views.seller.register.through_podcast") },
        { value: "social_medias", text: this.$t("views.seller.register.through_social_medias") },
        { value: "online_research", text: this.$t("views.seller.register.through_online_research") },
        { value: "others", text: this.$t("views.seller.register.through_other") },
      ],
      affiliated_options: [
        { value: "produtos", text: this.$t("views.seller.register.text_1441") },
        { value: "afiliado", text: this.$t("views.seller.register.text_1442") },
      ],
      seller_type_options: [
        { value: "zero", text: this.$t("views.seller.register.text_1443") },
        { value: "1-a-10", text: this.$t("views.seller.register.text_1444") },
        { value: "11-a-50", text: this.$t("views.seller.register.text_1445") },
        { value: "51-100", text: this.$t("views.seller.register.text_1446") },
        { value: "101-300", text: this.$t("views.seller.register.text_1447") },
        { value: "301-999", text: this.$t("views.seller.register.text_1448") },
        { value: "1m", text: this.$t("views.seller.register.text_1449") },
        {
          value: "nao-informado",
          text: this.$t("views.seller.register.text_1450"),
        },
      ],
    };
  },
  // mixins: [crisp],
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    }, 
    messageSuccess(msg, title) {
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 8000,
        appendToast: true,
      });
    },
    onSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let ambassador_id = null;
          if (this.$route.query.emb) {
            ambassador_id = this.$route.query.emb;
          }

          let data = {
            name: this.name,
            email: this.email,
            password: this.password,
            cellphone: this.phone,
            cpf: this.cpf,
            cnpj: this.cnpj,
            seller_type: this.seller_type,
            affiliated: this.affiliated,
            howDidYouFindVoomp: this.howDidYouFindVoomp,
            terms: this.accepted_terms,
            captcha: this.captcha,
            ambassador_id: ambassador_id,
          };

          if (this.id_indicacao) {
            data.confio = this.id_indicacao;
          }
          service
            .create(data)
            .then(({ access_token, token_type }) => {

              this.messageSuccess(this.$t('views.seller.register.text_1459'), this.$t('views.seller.register.text_1458'))

              //const bearer_access_token = `${token_type} ${access_token}`;
              //Cookies.set("access_token", bearer_access_token, {
              //  expires: 365,
              //});
              //this.$store.commit("authSuccess", bearer_access_token);
              setInterval(() => {
                if (this.hash) {
                  this.$router.push(
                    `/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`
                  );
                } else {
                  this.$router.push(`/verificar-email/`);
                }
              }, 2000);
            })
            .catch((err) => {
              this.$refs.Captcha.resetCaptcha();
              if (
                err.response.status == 422 &&
                err.response.data &&
                err.response.data.email.length
              ) {
                const h = this.$createElement;
                const a = h(
                  "a",
                  {
                    on: {
                      click: (e) => {
                        e.preventDefault();
                        this.$router.push({
                          path: "/",
                          query: {
                            email: this.email,
                          },
                        });
                      },
                    },
                    attrs: {
                      href: "/",
                    },
                  },
                  this.$t("views.seller.register.text_1452")
                );

                this.$bvToast.toast([a], {
                  title: this.$t("views.seller.register.text_1453"),
                  variant: "info",
                  noAutoHide: true,
                  appendToast: true,
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },     
  },
  mounted() { 
    if (typeof this.$route.query.hash_a != "undefined") {
      this.hash = this.$route.query.hash_a;
    }
    if (typeof this.$route.query.p_id != "undefined") {
      this.id_product = this.$route.query.p_id;
    }
    if (typeof this.$route.query.confio != "undefined") {
      this.id_indicacao = +this.$route.query.confio;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: $base-color;
}

.left-side {
  background-image: url("~@/assets/img/cadastro-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.right-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;
  right: 0;
  position: absolute;

  .inner-wrapper {
    margin-top: 20px;
    padding: 5% 12%;
  }
}

@media screen and (max-width: 768px) {
  .inner-wrapper form {
    padding-bottom: 40px;
  }

  .inner-wrapper {
    padding: 6% 11% !important;
  }

  .grecaptcha-badge {
    right: -194px !important;
  }
}

.btn-submit {
  background-color: #0a1e3e;
  border: 0;
  height: 49px;
  width: 100%;
  margin-bottom: 0 !important;
}

.linha-possui-conta {
  display: inline-flex;
  font-size: 10pt;
  color: #7c8089;
  width: 100%;
}

.linha-possui-conta a {
  display: inline-flex;
  font-size: 10pt;
  color: #f58322;
}

.linha-possui-conta a img {
  transform: rotate(180deg);
  height: 8px;
  top: 6px;
  margin-left: 8px;
  position: relative;
}


.button:disabled {
  color: #E6E9EC;
  background-color: #9AA3B0 !important;
}

.btn-acoes {
  margin: 0 auto;
}

.links-help {
  font-size: 14px;
  color: #81858e;
}


@media screen and (max-height: 946px) {
  .full-height {
    height: 100% !important;
  }
}

.termos-uso {
  margin-left: 25px;
}

.termos-privacidade {
  text-align: center;
  margin-top: 40px;
}

.text-termos {
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.text-termos a {
  color: #f58322;
}
</style>
