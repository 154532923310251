<template>
    <div class="voltar-login">
        <router-link :to="to">
            <img src="~@/assets/img/icons/long-arrow.svg" /> {{ text }}
        </router-link>
    </div>
</template>
  
<script>
export default {
    props: {
        to: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
    }
}
</script>
<style lang="scss" scoped>
.voltar-login {
    margin-bottom: 25px;
}

.voltar-login a {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 12px;
    font-weight: bold;
    max-width: 80px;
}

.voltar-login img {
    margin-right: 8px;
    width: 16px;
    font-weight: bold;
}
</style>
  